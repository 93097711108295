<template>
  <v-app-bar id="app-bar" absolute app color="#D3D3D3" flat height="40" class="mt-0">

    <el-button icon="el-icon-menu" small @click="setDrawer(!drawer)" class="mr-3 p-2" max-width="40" type="primary"> </el-button> 
    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light" v-text="$route.name" />
    <v-spacer />

    <div>
        <ui-button has-dropdown size="normal" color="#D3D3D3">
            <div class="keen-docs__custom-popover-content m-2 p-2" slot="dropdown">
                <div class="col" style="cursor:pointer;" v-if="passwordEdit ==false">
                  <div class="subtitle-1 font-weight-light" @click="showPassword()" > Modifier mon mot de passe </div>
                </div>

                <div v-else>
                  <div class="subtitle-1 font-weight-light" @click="showPassword()" > Saisir le nouveaux mot de passe </div>

                  <input type="password" class="form-control mt-2" placeholder="Mot de passe" v-model="form.password">
                  <input type="password" class="form-control mt-2" placeholder="Confirmer" v-model="form.password2">
                  <el-button class="mt-2" type="primary" style="border-radius:0px" @click="checkPassword()">Enregister</el-button>
                </div>
            </div>
          {{userInfos.name}}
        </ui-button>
    </div>

    <!-- <div class="p-2">
      <el-radio-group v-model="lang" size="mini" @change="isAllLocations()">
          <el-radio-button label="Fr"></el-radio-button>
          <el-radio-button label="En"></el-radio-button>
      </el-radio-group>
    </div> -->

    <div class="subtitle-1 font-weight-light ml-2" style="cursor:pointer;" @click="showDelog()"><v-icon>el-icon-switch-button</v-icon> Déconnexion </div>
  
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib';
  import NProgress from 'nprogress';
  import 'nprogress/nprogress.css';
  import axios from 'axios';
  // Utilities
  import { mapState, mapMutations } from 'vuex'

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },

    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
 
    data: () => ({

      passwordEdit: false,
      form: {
        password : '',
        password2 : ''
      },

      notifications: [
        'Bienvenue sur X-Peformance',
      ],

      lang : 'En',

    }),
    created() {
          this.initLang(this.$i18n.locale);
          this.$store.dispatch('getUserInfos');
    },

    computed: {
      ...mapState(['drawer','userInfos']),
    },

    methods: {

      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),

      showPassword(){
        this.passwordEdit = true;
        this.form = {};
      },

      async savePassword(){
        
        NProgress.start();
        this.passwordEdit = false;

        try {
          const response = await axios.post('v1/xperf/setting/user/editPassword', this.form);
          this.$swal(
            'Enregistrer!',
            'Votre mot de passe à été modifier avec succès',
            'success'
          );

        }
        catch (error) {
          this.$swal(
            'Erreur!',
            "Echec de modification. Merci d'essayer plus tard",
            'error'
          )
        }

        NProgress.done();
      },

      checkPassword(){
        if (this.form.password === this.form.password2) {
          this.showAlert();
        }
        else{
          this.$swal(
            'Erreur!',
            'Le mot de passe ne corespond pas',
            'error'
          )
        }
      },

      showAlert(){
          this.passwordEdit = false;
          this.$swal({
          title: 'Poursuivre ?',
          text: "Votre mot de passe vas être modifier",
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d13737',
          confirmButtonText: 'Oui'
        }).then((result) => {
          if (result.value) {
            this.savePassword();
          }
        });
      },

      showDelog(){
          this.$swal({
          title: 'Confirmer ?',
          text: "Vous ête sur le point d'être déconnecter poursuivre ?",
          type: 'question',
          // showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d13737',
          confirmButtonText: 'Oui'
        }).then((result) => {
          if (result.value) {
            this.logout();
          }
        });
      },

      logout() {
          NProgress.start();
          localStorage.clear();
          this.$auth.logout({
                makeRequest: true,
                redirect: {name: 'login'},
          });

          NProgress.done();
      },

      isAllLocations(){

        console.log('change');

        this.$swal({
          title: 'Confirmer ?',
          text: "Vous ête sur le point de changer la langue",
          type: 'question',
          // showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d13737',
          confirmButtonText: 'Oui'
        }).then((result) => {
          if (this.lang == 'Fr') {
              this.$i18n.locale = 'fr' 
          }else{
              this.$i18n.locale = 'en' 
          }
        });
                            
      },

      initLang(param){
        if (param == 'fr') { this.lang = 'Fr'; }
        else{ this.lang = 'En'; }
      }
    },
  }
</script>
